import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Carousel from "./components/Carousel";
import Patrocinios from "./components/Patrocinios";
import MultiActionAreaCard from "./components/News";
import Team from "./components/Team";
import Eventos from "./components/Eventos";
import Contacto from "./components/Contacto";
import Footer from "./components/Footer";
import Cookies from "./components/Cookies";

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Patrocinios />
      <Carousel />
      <MultiActionAreaCard />
      <Eventos />
      <Team />
      <Contacto />
      <Footer />
      <Cookies />
    </div>
  );
}

export default App;
