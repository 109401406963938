import React from "react";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import carusel1 from "../assets/carusel/1.jpg";
import carusel2 from "../assets/carusel/2.jpg";
import carusel3 from "../assets/carusel/3.jpg";

// Import Swiper styles

import "swiper/css/bundle";
import "swiper/css/autoplay";

const Carousel = () => {
  return (
    <div name="" className="text-center text-white bg-img">
      <div>
        <h1>PRÓXIMAMENTE</h1>

        <div
          id="carou"
          className=" md:p-3 2xl:px-44 2xl:py-20 flex justify-center  "
        >
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={true}
          >
            <SwiperSlide className="carousel-item">
              <img src={carusel1} alt="" />
            </SwiperSlide>
            {/* <SwiperSlide className="carousel-item">
              <img src={carusel2} alt="" />
            </SwiperSlide>
            <SwiperSlide className="carousel-item">
              <img src={carusel3} alt="" />
            </SwiperSlide> */}
            ...
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
