import React, { Fragment, useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import logoLz from "../assets/logo-sm.png";
import { Link } from "react-scroll";
import Popu from "./Popu";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const hadleNav = () => {
    setNav(!nav);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showPopu, setPopu] = useState(false);
  const handleOnClose = () => setPopu(false);
  const handleClick = () => {
    setPopu(true);
    setTimeout(() => {
      document.querySelector(".popu").classList.add("active");
    }, 50);
  };

  return (
    <Fragment>
      <div
        className={`flex w-full justify-between items-center h-20 px-4 fixed z-10 text-white ${
          scrollPosition > 820 ? "bg-imgs" : ""
        }`}
      >
        <div>
          <Popu onClose={handleOnClose} visible={showPopu} />
        </div>

        <ul className="hidden lg:flex ">
          <li onClick={handleClick} className=" translate-y-1">
            PROGRAMACIÓN
          </li>
          <li className=" translate-y-1">
            <Link to="noticias" smooth={true} duration={500}>
              NOTICIAS
            </Link>
          </li>
          <li
            id="imgLi"
            className="ssm-l hover:scale-110 duration-500"
            style={{ width: "80px" }}
          >
            <Link to="video" smooth={true} duration={500}>
              <img src={logoLz} alt="" />
            </Link>
          </li>
          <li className=" translate-y-1">
            <Link to="eventos" smooth={true} duration={500}>
              EVENTOS
            </Link>
          </li>
          <li className=" translate-y-1">
            <Link to="equipo" smooth={true} duration={500}>
              EQUIPO
            </Link>
          </li>
          <li className=" translate-y-1">
            <Link to="contact" smooth={true} duration={500}>
              CONTACTO
            </Link>
          </li>
        </ul>

        <div></div>
        {/* hamburguer */}
        <div onClick={hadleNav} className="lg:hidden z-10 py-4">
          {nav ? <AiOutlineClose size={20} /> : <HiOutlineMenuAlt4 size={20} />}
        </div>

        {/* Mobile Menu Dropdown*/}
        <div
          onClick={hadleNav}
          className={
            nav
              ? "absolute  left-0 top-0 w-full bg-gray-0 flex flex-col opacity-100 duration-500"
              : "absolute opacity-0"
          }
        >
          <ul id="list" className={`${scrollPosition > 500 ? "bg-imgs2" : ""}`}>
            <Link onClick={hadleNav} to="video" smooth={true} duration={500}>
              <img src={logoLz} alt="" />
            </Link>
            <li onClick={() => setPopu(true)} className=" translate-y-1">
              PROGRAMACIÓN
            </li>
            <li className=" translate-y-1">
              <Link
                onClick={hadleNav}
                to="noticias"
                smooth={true}
                duration={500}
              >
                NOTICIAS
              </Link>
            </li>
            <li className=" translate-y-1">
              <Link
                onClick={hadleNav}
                to="eventos"
                smooth={true}
                duration={500}
              >
                EVENTOS
              </Link>
            </li>
            <li className=" translate-y-1">
              <Link onClick={hadleNav} to="equipo" smooth={true} duration={500}>
                EQUIPO
              </Link>
            </li>
            <li className=" translate-y-1">
              <Link
                onClick={hadleNav}
                to="contact"
                smooth={true}
                duration={500}
              >
                CONTACTO
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Navbar;
