import React, { useState, useRef, useEffect } from "react";
import videoBg from "../assets/Logo.mp4";
import videoBg2 from "../assets/Logo2.mp4";
import fondoZ from "../assets/iPhone-z2.png";

const Hero = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState(videoBg);
  const isIPhone = /iPhone/.test(navigator.userAgent);

  const handlePlayPause = () => {
    const audio = audioRef.current;

    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setVideoSrc(videoBg2);
      } else {
        setVideoSrc(videoBg);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div name="video" className="w-full h-screen relative">
      {isIPhone ? (
        <img
          className="w-full h-full object-cover"
          src={fondoZ}
          alt="fondo-z"
        />
      ) : (
        <video
          className="w-full h-full object-cover"
          src={videoSrc}
          autoPlay
          loop
          muted
        ></video>
      )}

      <div className="absolute w-full h-full top-0 left-0 bg-gray-0"></div>

      <div id="btnP2" className="w-full h-full">
        <div
          onClick={handlePlayPause}
          id="btnP"
          className={isPlaying ? "fas fa-pause fa-2xl" : "fas fa-play"}
        >
          <audio id="audio-player" ref={audioRef} controls autoPlay>
            <source
              src="https://cloudstream2036.conectarhosting.com/8290/stream"
              type="audio/mpeg"
            />
            Tu navegador no soporta la reproducción de audio.
          </audio>
        </div>
      </div>
    </div>
  );
};

export default Hero;
