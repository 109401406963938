import React, { useRef } from "react";

const Contacto = () => {
  const formRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    // Validar que los campos obligatorios no estén vacíos
    const nombre = formData.get("nombre");
    const email = formData.get("email");
    const mensaje = formData.get("mensaje");
    if (!nombre || !email || !mensaje) {
      alert("Por favor complete los campos obligatorios");
      return;
    }
    // Envía el formulario usando fetch, axios, u otra librería
    fetch("https://getform.io/f/47c92464-9c32-40ae-a1e2-b52adf34b3ec", {
      method: "POST",
      body: formData,
    })
      .then(() => {
        alert("¡Formulario enviado correctamente!");
        // Resetea el formulario
        formRef.current.reset();
      })
      .catch((error) => {
        alert("¡Hubo un error al enviar el formulario!");
        console.error(error);
      });
  };

  return (
    <div id="contact" name="contact" className="bg-img">
      <div className="text-center text-white ">
        <h1>CONTACTO</h1>
      </div>
      <div className=" flex justify-center items-center py-6">
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="flex flex-col max-w-[600px] w-full text-white"
        >
          <p className="py-4 text-center">
            Envía el formulario a continuación o envíame un correo electrónico a
            contacto@lazfmradio.es
          </p>
          <br />
          <input
            className="p-2 text-black"
            type="text"
            placeholder="Ingrese su Nombre"
            name="nombre"
            required
          />
          <br />
          <input
            className="p-2 text-black"
            type="email"
            placeholder="Ingrese su Email"
            name="email"
            required
          />
          <br />
          <textarea
            className="p-2 text-black"
            name="mensaje"
            rows="10"
            placeholder="Envianos tu mensaje"
            required
          ></textarea>
          <button className="border-2 hover:bg-red-700 hover:border-black px-4 py-3 my-8 mx-auto flex item-center">
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contacto;
