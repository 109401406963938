import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import new1 from "../assets/news/bembe.jpg";
import new2 from "../assets/news/fashion.jpg";
import new3 from "../assets/news/app.jpg";
import { Fragment } from "react";

export default function MultiActionAreaCard() {
  return (
    <Fragment className="bg-gray-50">
      <div name="noticias" className="text-center">
        <h1> NOTICIAS</h1>
      </div>

      <div
        id="newsC"
        className="max-w-[1240px] mx-auto grid lg:grid-cols-3 md:grid-cols-2 gap-4 px-4 py-16"
      >
        <Card sx={{ maxWidth: 345 }} className="hover:scale-105">
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={new1}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                ¡Bembe Puerto ha llegado!
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ¡Bembe ya está aquí! Ven y disfruta de la inauguración de
                nuestro nuevo destino, Bembe Puerto en Puerto Valencia. Estamos
                emocionados de abrir nuestras puertas y queremos que seas parte
                de esta gran experiencia. ¡Te esperamos!
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary">
               
            </Button>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="hover:scale-105 ">
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={new2}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                2 Fashion Week
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ¡Descubre la última moda en Valencia en el 2º Fashion Week! Los
                domingos de Marzo, Abril y Mayo, podrás disfrutar de una
                experiencia única llena de glamour y estilo. ¡No te pierdas este
                espectáculo de moda que te dejará sin aliento!
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary">
               
            </Button>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="hover:scale-105 ">
          <CardActionArea href="https://play.google.com/store/apps/details?id=com.zonastreaming.radioz" target="Blank_">
            <CardMedia
              component="img"
              height="140"
              image={new3}
              alt="green iguana"
            />
            <CardContent >
              <Typography  gutterBottom variant="h5" component="div">
                ¡No te pierdas ni un solo evento!
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Descarga nuestra app y disfruta de toda la programación en la
                palma de tu mano. ¡Descárgala ahora y no te quedes fuera de
                nada!
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button href="https://play.google.com/store/apps/details?id=com.zonastreaming.radioz" target="Blank_" size="small" color="primary">
              Instalar
            </Button>
          </CardActions>
        </Card>
        
      </div>
    </Fragment>
  );
}
