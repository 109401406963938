import React, { useState } from "react";

const Cookies = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    // Aquí puedes guardar la preferencia del usuario en una cookie o en localStorage
  };

  if (accepted) {
    return null; // Si el usuario ya aceptó las cookies, no mostramos el banner
  }

  return (
    <div
      id="cookies"
      className="fixed bottom-3 right-3 w-52 bg-gray-700 bg-opacity-90 text-white p-2 rounded-lg z-20"
    >
      <p className="mb-2 text-center">
        Este sitio web utiliza cookies para mejorar su experiencia.
      </p>
      <div className="flex justify-center">
        <button
          className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded mr-2"
          onClick={handleAccept}
        >
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default Cookies;
