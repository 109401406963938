import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-50 text-gray-800 text-center py-4">
      <div className="flex justify-center my-4">
        <a
          href="https://www.facebook.com/laZfmteprende"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebook
            className="text-gray-800 mx-6 hover:scale-110 duration-300"
            size={60}
          />
        </a>
        <a
          href="https://www.instagram.com/lazetafmradio/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram
            className="text-gray-800 mx-6 hover:scale-110 duration-300"
            size={60}
          />
        </a>
      </div>
      <p className="py-6">
        Todos los derechos reservados &copy; 2023 La Z 91.8 FM <br />
        Creado por{" "}
        <a href="https://www.instagram.com/magproductions_/">
          {" "}
          MAG PRODUCTIONS
        </a>
      </p>
    </footer>
  );
};

export default Footer;
