import React from "react";
import prog from "../assets/carusel/4.jpg";

export default function Popu({ visible, onClose }) {
  const handleOnClose = () => {
    onClose();
  };
  if (!visible) return null;
  return (
    <div
      onClick={handleOnClose}
      className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm flex justify-center items-center"
    >
      <img id="prog" className="shadow-2xl 2xl:p-100 scale-50 " src={prog} alt="" />
    </div>
  );
}
