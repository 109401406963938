import React from "react";
import planet from "../assets/patrocinadores/planet.png";
import mag from "../assets/patrocinadores/mag.png";
import bembe from "../assets/patrocinadores/bembe.jpg";
import kche from "../assets/patrocinadores/kche.png";
import botero from "../assets/patrocinadores/botero.png";

const Patrocinios = () => {
  return (
    <div cla>
      <div className="bg-gray-50 py-16 sm:py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
            ¡Únete a la lista de clientes satisfechos que confían en nosotros!
          </h2>
          <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            <a href="https://www.instagram.com/planetfestival_group/" target="blank_"> <img
              className="col-span-2 max-h-25 w-full object-contain lg:col-span-1 hover:scale-125"
              src={planet}
              alt="Planet Group"
              width={158}
              height={48}
            /> </a>

            <a href="https://www.instagram.com/magproductions_/" target="blank_">
            <img
              className="col-span-2 max-h-25 w-full object-contain lg:col-span-1 scale-75 hover:scale-100"
              src={mag}
              alt="mag"
              width={158}
              height={48}
            />

            </a>
            <a href="https://www.instagram.com/bembepuerto/" target="blank_">
            <img
              className="col-span-2 max-h-24 w-full object-contain lg:col-span-1 hover:scale-125"
              src={bembe}
              alt="Tuple"
              width={158}
              height={48}
            />

            </a>
            <a href="https://www.instagram.com/discotecakche/" target="blank_">
            <img
              className="col-span-2 max-h-25 w-full object-contain sm:col-start-2 lg:col-span-1 hover:scale-125"
              src={kche}
              alt="SavvyCal"
              width={158}
              height={48}
            />
            </a>
            {/* <img
              className="col-span-2 col-start-2 max-h-25 w-full object-contain sm:col-start-auto lg:col-span-1"
              src={}
              alt="Statamic"
              width={158}
              height={48}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Patrocinios;
