import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

import img2 from "../assets/eventosr/2.jpg";
import img3 from "../assets/eventosr/3.jpg";
import img4 from "../assets/eventosr/4.jpg";
import img5 from "../assets/eventosr/5.jpg";

const Eventos = () => {
  const [selectedImage, setSelectedImage] = useState(img2);

  return (
    <div name="eventos" className="bg-img">
      <div className="text-center text-white">
        <div>
          <h1>EVENTOS REALIZADOS</h1>
          <div className="max-w-[1080px] mx-auto py-2 px-4">
            <div className="grid drig-row-none md:grid-cols-5 py-4 gap-2 md:gap-4">
              <CSSTransition
                in={selectedImage === selectedImage}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <img
                  className="w-full h-full object-cover col-span-2 md:col-span-3 row-span-2"
                  src={selectedImage}
                  alt="/"
                />
              </CSSTransition>
              <CSSTransition
                in={selectedImage !== selectedImage}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <img
                  className="w-full h-full object-cover col-span-2 md:col-span-3 row-span-2"
                  src={selectedImage}
                  alt="/"
                />
              </CSSTransition>
              <img
                className="w-full h-full object-cover"
                src={img2}
                alt="/"
                onClick={() => setSelectedImage(img2)}
              />
              <img
                className="w-full h-full object-cover"
                src={img3}
                alt="/"
                onClick={() => setSelectedImage(img3)}
              />
              <img
                className="w-full h-full object-cover"
                src={img4}
                alt="/"
                onClick={() => setSelectedImage(img4)}
              />
              <img
                className="w-full h-full object-cover"
                src={img5}
                alt="/"
                onClick={() => setSelectedImage(img5)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eventos;
