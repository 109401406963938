import React, { Fragment } from "react";
import brand from "../assets/team/brand.jpg";

const people = [
  {
    name: "Brand Agudelo",
    role: "Gerente General",
    imageUrl: `${brand}`,
  },
  // {
  //   name: "Brand Agudelo",
  //   role: "Director",
  //   imageUrl: `${brand}`,
  // },
  // {
  //   name: "Brand Agudelo",
  //   role: "Director",
  //   imageUrl: `${brand}`,
  // },
  // {
  //   name: "Brand Agudelo",
  //   role: "Director",
  //   imageUrl: `${brand}`,
  // },

  // More people...
];

const Team = () => {
  return (
    <Fragment>
      <div name="equipo" className="text-center bg-gray-50">
        <h1>EQUIPO</h1>
      </div>
      <div className="py-10 sm:py-16 bg-gray-50">
        <div className="mx-auto grid max-w-7xl gap-y-10 gap-x-8 px-6 lg:px-8 xl:grid-cols-3 ">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl">
              Trabajando juntos para alcanzar tus metas
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-700">
              Cada uno de nosotros aporta habilidades y experiencia únicas para
              proporcionarte una atención personalizada y adaptada a tus
              necesidades
            </p>
          </div>
          <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            {people.map((person) => (
              <li key={person.name}>
                <div className="flex items-center gap-x-6">
                  <img
                    className="h-20 w-20 rounded-full"
                    src={person.imageUrl}
                    alt=""
                  />
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-800">
                      {person.name}
                    </h3>
                    <p className="text-sm font-semibold leading-6 text-red-600">
                      {person.role}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Team;
